import React, { useState, useLayoutEffect, useEffect } from "react"

class UserScheme {
  constructor(public name: string, public color: string) {}
}

class DefaultScheme {
  public name = "auto"
}

type Scheme = UserScheme | DefaultScheme

const SCHEME_ORDER: Scheme[] = [
  new DefaultScheme(),
  new UserScheme("dark", "#002b36"),
  new UserScheme("light", "#fdf6e3"),
]
const DEFAULT_THEME = "solarized"

export const ColorschemeContext = React.createContext({
  scheme: SCHEME_ORDER[0],
  next: SCHEME_ORDER[1],
  theme: DEFAULT_THEME,
  toggle: () => {},
  setTheme: (theme: string) => {},
})

export const ColorschemeProviderFactory = ({ element }: any) => (
  <ColorschemeProvider>{element}</ColorschemeProvider>
)

export default function ColorschemeProvider({ children }: any) {
  const [scheme, setScheme] = useState(SCHEME_ORDER[0])
  const [next, setNext] = useState(SCHEME_ORDER[1])
  const [theme, setTheme] = useState(DEFAULT_THEME)

  const toggle = () => {
    const idx = SCHEME_ORDER.indexOf(next)
    setScheme(next)
    setNext(SCHEME_ORDER[idx >= 0 ? (idx + 1) % SCHEME_ORDER.length : 0])
  }

  function applyColorscheme() {
    const root = document.getElementsByTagName("body")[0]
    const themeColorEl = document.querySelector('meta[name="theme-color"]')
    const color = scheme instanceof UserScheme ? scheme.color : ""
    themeColorEl?.setAttribute("content", color)

    root.classList.remove(
      ...SCHEME_ORDER.filter(c => c !== scheme).map(t => (t ? t.name : "auto"))
    )
    root.classList.add("colorscheme", theme, scheme ? scheme.name : "auto")
  }

  useLayoutEffect(() => {
    applyColorscheme()
  }, [scheme])

  return (
    <ColorschemeContext.Provider
      value={{
        scheme,
        next,
        toggle,
        theme,
        setTheme,
      }}
    >
      {children}
    </ColorschemeContext.Provider>
  )
}
