import { ColorschemeProviderFactory } from "./src/components/colorscheme"

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

require("prismjs/plugins/line-numbers/prism-line-numbers.css")

export const wrapRootElement = ColorschemeProviderFactory
